import React, { useState } from "react";
import Monthly from "./Monthly";
import Weekly from "./Weekly";

const App = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  return (
    <div className="Tabs">
      <ul className="nav">
        <li className={activeTab === "tab1" ? "active" : ""} onClick={() => setActiveTab("tab1")}>Mensalmente</li>
        <li className={activeTab === "tab2" ? "active" : ""} onClick={() => setActiveTab("tab2")}>Anualmente</li>
      </ul>
      <div className="outlet">
        {activeTab === "tab1" ? <Weekly/> : <Monthly/>}
      </div>
    </div>
  );
};
export default App;