import './App.css';

function Weekly() {
  return (
    <div>
      <stripe-pricing-table pricing-table-id="prctbl_1Pj43xKlMlxRP9l7dWM6tLRx"
      publishable-key="pk_live_51PEuWEKlMlxRP9l7QPVAN0WMvdwS4tn6X2xEJLBcQmMXQ7DdH28Tvnybi3Mey9KxPsuHV55GOUc5f1hLnXXfQNqK00IK9cpivv">
      </stripe-pricing-table>
    </div>
  );
}

export default Weekly;
